import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../components/layout/footer";
import ProductCard from "../components/common/productCard";
import BasicPagination from "../components/common/basicPagination";
import BreadCrumbs from "../components/common/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestGetProductsTest } from "../store/products/productsSlice";

import Skeleton from "@mui/material/Skeleton";
import { ProductProps } from "../types/product";
import { useTranslation } from "react-i18next";
import EmptyItems from "../components/common/emptyItems";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { RequestGetColor } from "../store/color/colorSlice";

import { useSearchParams } from "react-router-dom";
import { extractExistingParams } from "../utils/helper-function";
import ShopFilterParams from "../components/shop/shopFilterParams";
import ShopFilterDrewer from "../components/shop/shopFilterDrewer";
import { Helmet } from "react-helmet";

const Shop = () => {
  //-------- STATE
  const [filterMobile, setFilterMobile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation();
  const location = useLocation();

  //-------- Redux
  const dispatch = useDispatch<AppDispatch>();

  //-------- SELEBTOR
  const { loading, filter, products, breadCate } = useSelector(
    (state: RootState) => state.products
  );
  const lang = useSelector((state: RootState) => state.global.mainLang);
  const { results }: any = useSelector(
    (state: RootState) => state.products.products
  );

  //-------- CONSTANT --------
  const Options = [
    { name: t("high_to_low"), value: "-price" },
    { name: t("low_to_high"), value: "price" },
    { name: t("newest_arrival"), value: "-created_at" },
  ];

  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: breadCate,
      link: "/shop",
      active: true,
    },
  ];

  // ------------------------------
  // -------- ONCE CHANGE --------
  // ------------------------------
  useEffect(() => {
    dispatch(RequestGetColor());
  }, [dispatch]);

  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    dispatch(RequestGetProductsTest({ ...params }));
  }, [location.pathname, searchParams]);

  // -------- GLOBAL FUNC --------
  const handleCloseDrawerFilter = () => {
    setFilterMobile(false);
  };

  const handleSorting = (e: { target: { value: string } }) => {
    setSearchParams({
      ...extractExistingParams(searchParams),
      ordering: e.target.value,
    });
  };

  return (
    <Box>
       <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'PageView');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
      <Container>
        <Box mt="48px" sx={{ px: { md: "0px" } }}>
          <BreadCrumbs crumbs={crumbs} />
        </Box>
        <Grid container mt="24px" spacing={3}>
          {!isMobile ? (
            <Grid
              item
              xs={0}
              sm={0}
              md={3}
              lg={3}
              xl={2}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  background: "rgba(251, 251, 251, 1)",
                  height: "100%",
                  borderRadius: "10px",
                  padding: "0px 0",
                }}
              >
                <ShopFilterParams />
              </Box>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
                  {breadCate}
                </Typography>
                <Button
                  onClick={() => setFilterMobile(true)}
                  sx={{
                    display: {
                      md: "none",
                      xs: "block",
                      fontWeight: 500,
                      gap: 1,
                      fontSize: "18px",
                      color: "linear-gradient(90deg, #F5841F 0%, #ED1C24 100%)",
                    },
                  }}
                >
                  <FilterAltOutlinedIcon />
                  {t("filter")}
                </Button>
              </Box>

              <Stack
                direction="row"
                justifyContent={"space-between"}
                mt="12px"
                mb="45px"
              >
                <Typography>
                  {results?.length > 0 ? results?.length : 0}
                  {t("100 result")}
                </Typography>
                {/* TODO: CUSTOM SELECT  */}
                <FormControl
                  sx={{
                    boxShadow: "none",
                    outline: "none",
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                        outline: "none",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                        outline: "none",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused": {
                      border: "none",
                      outline: "none",
                    },
                    "&.MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      outline: "none",
                    },
                    "& fieldset": {
                      border: "none",
                      outline: "none",
                    },
                    fontWeight: "500",
                  }}
                >
                  <Select
                    // variant="standard"
                    value={searchParams.get("ordering") || ""}
                    onChange={handleSorting}
                    sx={{
                      fieldset: { border: "none" },
                    }}
                    style={{
                      border: "none",
                      fontWeight: "600",
                      outline: "none",
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} sx={{ display: "none" }}>
                      {t("ordering_by")}
                    </MenuItem>
                    {Options.map((ele) => (
                      <MenuItem key={ele.value} value={ele.value}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {loading ? (
                <Grid container spacing={3}>
                  <>
                    {/* TODO: CUSTOM comoponet  */}
                    {Array.from(new Array(12)).map((item) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={250}
                            sx={{
                              textAlign: "center",
                              display: "block",
                              margin: "auto",
                            }}
                          />
                          <Skeleton variant="text" width="80%" />
                          <Skeleton variant="text" width="80%" />
                        </Grid>
                      );
                    })}
                  </>
                </Grid>
              ) : results?.length > 0 ? (
                <Grid container spacing={3}>
                  {results?.map((product: ProductProps) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        key={product.id}
                      >
                        <ProductCard type="new" product={product} />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                ""
              )}
              {results?.length === 0 && (
                <Grid item xs={12} md={12} sm={12}>
                  <EmptyItems loading={loading} />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box mt="40px" sx={{ direction: lang === "en" ? "ltr" : "ltr" }}>
          {products.count > 9 && <BasicPagination products={products} />}
        </Box>
      </Container>
      <ShopFilterDrewer open={filterMobile} onClose={handleCloseDrawerFilter} />
      <Footer />
    </Box>
  );
};

export default Shop;
