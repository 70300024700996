import { useEffect, useState } from "react";
import "./App.css";


import { Box, CssBaseline } from "@mui/material";

import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home";
import ProductDetails from "./pages/productDetails";
import Shop from "./pages/shop";
import SavedItems from "./pages/savedItems";
import Comparsion from "./pages/comparsion";
import Cart from "./pages/cart";
import SideCart from "./components/cart/sideCartDialog";
import Checkout from "./pages/checkout";
import AccountDetailsEdit from "./pages/profile/accountDetailsEdit";
import Account from "./pages/profile/account";
import Order from "./pages/profile/order";
import OrderDetails from "./pages/profile/orderDetails";
import Privacy from "./pages/profile/privacy";
import LastNav from "./components/layout/lastNav";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import PrivateRoute from "./components/layout/privateRoute";
import { RequestGetCart } from "./store/cart/cartSlice";
import { RequestGetGuestCart } from "./store/guestCart/guestCart";
import { setOpenDrawerCart } from "./store/globalSlice";
import { RequestGetWishList } from "./store/wishlist/wishlistSlice";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import PaymentSuccess from "./pages/paymentSuccess";
import PaymentFail from "./pages/paymentFail";
import RefundAndReturnsPolicy from "./pages/profile/RefundAndReturnsPolicy";
import TermsCondition from "./pages/profile/TermsCondition";
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';

function App() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.user.user);
  const guestToken = useSelector((state: RootState) => state.guestCart.cartID);

  const openDrawerCart = useSelector((state: RootState) => state.global.openDrawerCart)


  const { i18n } = useTranslation();

  let [lang, setlang] = useState(
    window.location.pathname.startsWith("/ar") ? "ar" : "en"
  );

  const i18nextLng: any = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ar";

  useEffect(() => {
    setlang(i18nextLng);
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    if (user) {
      dispatch(RequestGetCart());
      dispatch(RequestGetWishList());
    }

    if (guestToken) {
      dispatch(RequestGetGuestCart())
    }
  }, [user, guestToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Box
        className="App"
        sx={{ direction: i18nextLng == "ar" ? "rtl" : "ltr" }}
      >
        <CssBaseline />
        <LastNav />
        <div
          style={{
            flex: 1,
            height: "100%",
            margin: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<Account />} />
              <Route path="/profile/edit" element={<AccountDetailsEdit />} />
              <Route path="/saved" element={<SavedItems />} />
            </Route>
            <Route path="/profile/privacy" element={<Privacy />} />
            <Route path="/profile/order" element={<Order />} />
            <Route path="/profile/order/:id" element={<OrderDetails />} />
            <Route path={`/${lang}`} element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/profile/refund&returnsPolicy" element={<RefundAndReturnsPolicy/>}/>
            <Route path="/profile/terms&condition" element={<TermsCondition/>}/>
            <Route path="/shop/:id" element={<ProductDetails />} />
            <Route path="/category/:id" element={<Shop />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/comparsion" element={<Comparsion />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/purchase/success" element={<PaymentSuccess />} />
            <Route path="/fail" element={<PaymentFail />} />
          </Routes>
        </div>
        <ToastContainer position="bottom-left" />
        <SideCart open={openDrawerCart} onClose={() => dispatch(setOpenDrawerCart(false))} />
      </Box>
    </>
  );
}

export default App;
