import { Box, Container, Grid } from "@mui/material";
import { useEffect } from "react";
import Footer from "../components/layout/footer";
import ProductCard from "../components/common/productCard";
import EmptyItems from "../components/common/emptyItems";
import HeroHeading from "../components/common/HeroHeading";
import BreadCrumbs from "../components/common/breadCrumbs";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import { RequestGetWishList, } from "../store/wishlist/wishlistSlice";
import savedEmpty from '../assets/common/savedEmpty.png'
import { Helmet } from "react-helmet";

const SavedItems = () => {
    // -------- SELECTORS --------
    let result = useSelector((state: any) => state.wishlists.wishlists);
    let loading = useSelector((state: any) => state.wishlists.loading);

    // -------- DISPATCH --------
    const dispatch = useDispatch<AppDispatch>();

    // -------- CONSTANTS --------
    const crumbs = [{ label: t("Home"), link: "/", active: false }, {
        label: t("saved items"),
        link: "/saved",
        active: true
    }];

    // -------- ONCE CHANGE --------
    useEffect(() => {
        dispatch(RequestGetWishList());
    }, []);
    
    return (
        <Box>
            <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'PageView');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
            <Container>
                {/* ------- BreadCrumbs------- */}
                <Box mt="48px" mb="40px">
                    <BreadCrumbs crumbs={crumbs} />
                </Box>

                {/* ------- HeroHeading ------- */}
                <HeroHeading heading={t("your saved items")} />

                {/* ------- PRODUCTS ------- */}
                <Grid container spacing={3}>
                    {result?.at(0)?.product_wishlist?.map((product: any) => (<Grid item xs={12} sm={6} md={4} lg={3}>
                        <ProductCard type="new" product={product} />
                    </Grid>))}
                </Grid>

                {/* <EmptyItems /> */}
                {result?.at(0)?.product_wishlist?.length === 0 && <EmptyItems image={savedEmpty} loading={loading} />}
            </Container>
            <Footer />
        </Box>);
};

export default SavedItems;
