import { useRef, useEffect, useState, useCallback } from "react";
import { H2 } from "../../style/style";
import { Box, Skeleton } from "@mui/material";
import ProductCard from "../common/productCard";
import Slider from "react-slick";

import productImage from "../../assets/recommend/product-6.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestGetLike } from "../../store/recommended/recommendedSlice";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";

function debounce(fn:any, delay:any) {
  let timeoutId:any;
  return function(...args:any) {
    if (timeoutId) {
      clearTimeout(timeoutId);  // Clear previous timeout
    }
    timeoutId = setTimeout(() => {
      fn(...args);  // Execute the function after delay
    }, delay);
  };
}
interface RecommendedState {
  heading?: string;
  fetchUrl?: string;
  categoryIds?:any;
}

const LikeYouSection = ({
  heading = t("You may also like"),
  categoryIds,
  fetchUrl = `/likedproducts/`,
}: RecommendedState) => {
  // ---------- SELECTOR ----------
  const { like, likeLoading } = useSelector(
    (state: RootState) => state.recommended
  );

  // ---------- DISPATCH -----------
  const dispatch = useDispatch<AppDispatch>();

  // ---------- CONSTANTS --------
  const settings = {
    className: "serv",
    infinite: true,
    slidesToShow: like?.length > 6 ? 6 : like?.length,
    slidesToScroll: 2,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const arrowRef = useRef<Slider>(null);

  // Add state variable to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [category, setCategory] = useState<any>([categoryIds||null]);
  const navigate = useNavigate();
  // ---------- HANDLERS ----------
  const handlePrevClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickPrev();
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const handleNextClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickNext();
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };
  const debouncedGetData = useCallback(
    debounce((ids:any) => {
      dispatch(RequestGetLike(`/likedproducts/?category_ids=${ids ? ids : ''}`));
    }, 300),  // Delay of 300ms (adjust as needed)
    []
  );
  useEffect(() => {
    if (categoryIds) {
      debouncedGetData(categoryIds);  // Call the debounced function
    }
  }, [categoryIds, debouncedGetData]);
  // function getData(ids:any){
  //   dispatch(RequestGetLike(`/likedproducts/?category_ids=${ids?ids:''}`));  
  // }
  return (
    <>
    <Box sx={{display:"flex", alignItems:"center", justifyContent: "space-between"}} mb="24px" mt="48px">
      <H2 variant="h2" >
        {heading} 
      </H2>

      <Link to={`/shop/`} style={{color:"#000", textDecoration:"none", fontWeight:"500"}}>
        {t("view_all")}
      </Link>
    </Box>

      <div style={{ position: "relative" }}>
        {likeLoading ? (
          <Slider {...settings} ref={arrowRef}>
            {Array.from(new Array(12)).map((item,index) => {
              return (
                <Box
                  key={index}
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={250}
                    sx={{
                      textAlign: "center",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  <Skeleton variant="text" width="100%" />
                </Box>
              );
            })}
          </Slider>
        ) : (
          <Slider {...settings} ref={arrowRef}>
            {like?.map((product: any) => (
              <Box
                key={product.id}
                
              >
                <ProductCard type="" image={productImage} product={product} />
              </Box>
            ))}
          </Slider>
        )}

        <div className="slider-arrow">
          {currentSlideIndex > 0 && (
            <button onClick={handlePrevClick} className="back">
              <NavigateBeforeIcon />
            </button>
          )}
          <button onClick={() => handleNextClick()} className="next">
            <NavigateNextIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default LikeYouSection;
