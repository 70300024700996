import { Typography, Box } from "@mui/material";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../assets/payment/animate-2.json";
import { MainButton } from "../style/style";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const PaymentFail = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  };

  const { View } = useLottie(options);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        flex: "1",
        textAlign: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ width: "300px", margin: "auto" }}>{View}</Box>
        <Typography>{t("fail1")}</Typography>
        <Typography>
          {t("fail2")} 
        </Typography>
      </Box>
      <Box sx={{ mt: "40px" }}>
        <MainButton onClick={() => navigate("/profile/order")}>
          {t("fail3")}
        </MainButton>{" "}
        <br />
        <button
          onClick={() => navigate("/")}
          className="main-text"
          style={{ marginTop: "15px", outline: "none", border: "none", cursor:"pointer" }}
        >
          {t("fail4")}
        </button>
        <Typography sx={{marginTop:"20px"}}>
          {t("fail5")} 
        </Typography>
        <Typography >
          {t("fail6")}
          <a  href="https://wa.me/201010628457" target="_blank" > {t("phone")}</a>
          </Typography>
      </Box>
    </Box>
  );
};

export default PaymentFail;
