import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, styled, Stack } from "@mui/material";
import { colors } from "../../utils/theme";
import { IconBox, MainButton } from "../../style/style";
import BasicTable from "../../components/productDetails/table";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import IosShareIcon from "@mui/icons-material/IosShare";
import { NavbarNav } from "../../pages/productDetails";
import { t } from "i18next";
import Rating from "@mui/material/Rating";
import useCustomHooks from "../../hooks/customHooks";
import { BsBookmark } from "react-icons/bs";
import useAddToCart from "../../hooks/useAddToCart";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import useCompare from "../../hooks/useCompare";
import { BiTransfer } from "react-icons/bi";
import { toast } from "react-toastify";
import VideoCard from "../home/videoCard";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgba(240, 194, 77, 1)",
  },
  "& .MuiRating-iconHover": {
    color: "rgba(240, 194, 77, 1)",
  },
});
const ColorCircle = styled(Box)<{ selected: boolean }>(({ selected }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  border: selected ? "2px solid #F5841F" : "2px solid #79BAEC",
  cursor: "pointer",
}));

const ProductsInfo = ({ product, onColorChange }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [quantity, setQuantity] = useState<any>(1);
  const token = useSelector((state: RootState) => state.user.user);
  const handleCompare = useCompare();
  const [selectedColorID, setSelectedColorID] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleAddItem = useAddToCart();
  const handleConsole = useCustomHooks();

  // Select the first color and its images by default
  useEffect(() => {
    if (product.color && product.color.length > 0) {
      const firstColor = product.color[0];
      setSelectedColorID(firstColor.id);
      setSelectedColor(firstColor.hex_value);
      setSelectedImage(firstColor?.images[0]?.image || firstColor?.mainImage);
      onColorChange(firstColor.images);
    }else{
      setSelectedImage(product?.image);
      setSelectedColor(null);
    }
  }, [product]);

  const handleColorSelect = (color: any) => {
    setSelectedColorID(color.id);
    setSelectedColor(color.hex_value);
    setSelectedImage(color.images[0]?.image);
    onColorChange(color.images);
  };

  console.log("product", product);
  
  return (
    <Stack direction="row">
         <Box sx={{ flex: 1 }}>
        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "29.26px",
              maxWidth: "607px",
              mb: "16px",
            }}
          >
            {product?.name}
          </Typography>
          <Box>
            <StyledRating
              name="half-rating"
              value={
                product?.avg_rating && !isNaN(product.avg_rating)
                  ? product.avg_rating
                  : 0
              }
              precision={1}
              readOnly
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            mt: "6px",
            mb: "24px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography
              component={"span"}
              sx={{ color: "", fontWeight: "400" }}
            >
              {product?.total_ratings_count} {t("ratings")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "3px",
              height: "3px",
              background: "#D9D9D9",
              borderRadius: "50%",
            }}
          ></Box>
          <Typography>
            {product?.avg_rating} {t("star average")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
          <Typography
            sx={{
              color: colors.blue[100],
              fontWeight: "600",
              fontSize: "28px",
            }}
            component={"span"}
          >
            {product?.price} {t("egp")}
          </Typography>
          <Typography
            component={"span"}
            sx={{
              textDecoration: "line-through",
              fontWeight: "400",
              fontSize: "18px",
              ml: "10px",
              mr: "10px",
            }}
          >
            {/* 4,200.00 */}

            {product?.old_price > 0 && product?.old_price}
          </Typography>
        </Box>
        {product?.color?.length > 0 && (
          <>
            <Typography sx={{ fontSize: "18px", fontWeight: "600", marginBottom: "10px" }}>
              {t("selectColor")}
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              {product?.color?.map((color: any) => (
                <ColorCircle
                  key={color.id}
                  selected={selectedColorID === color.id}
                  onClick={() => handleColorSelect(color)}
                  sx={{ backgroundColor: color.hex_value }}
                />
              ))}
            </Box>
          </>
        )}
        <Box sx={{ fontSize: "18px", mb: "32px" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            {t("About This Item")}
          </Typography>
          <NavbarNav>
            {product?.about_item?.split("\r\n").map((ele: string) => {
              if (ele.length > 0) {
                return <li>{ele}</li>;
              } else {
                return <p></p>;
              }
            })}
          </NavbarNav>
        </Box>

        {
          product?.video_review && <a target="_blank" rel="noreferrer" href={product?.videos[0]?.video} style={{ fontSize: "18px", fontWeight: "600", marginBottom: "20px", display: 'inline-block', textDecoration: "none" }}>
            {t("Video review")}
          </a>

        }

        {product?.video_review && <Box mb="30px" sx={{ "& p": { whiteSpace: "wrap" } }}>
          <VideoCard video={product?.videos[0]} />
        </Box>}

       

        <Box mb="40px" sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
          />
          <MainButton 
          disabled={product?.quantity === 0}
          onClick={() => handleAddItem(quantity, product?.id, selectedColor, selectedImage,product?.name)}>
            {t("AddToCart")}
          </MainButton>
        </Box>
        <BasicTable tech={product} />
      </Box>
    </Stack>
  );
};

export default ProductsInfo;
