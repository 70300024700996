import { useEffect, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import ServiceItem from "./serviceItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetCategories,
  setBread,
} from "../../store/products/productsSlice";
import { CategoriesState } from "../../types/categories";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";



const ServicesSection = () => {
  // --------- SELECTOR --------------- 
  const categories: CategoriesState[] = useSelector(
    (state: RootState) => state.products.cate
  );
  const cateLoading = useSelector(
    (state: RootState) => state.products.cateLoading
  );

  // ---------- DISPATCH ------------
  const dispatch = useDispatch<AppDispatch>();

  // ------- ROUTER ---------
  const navigate = useNavigate();

  const arrowRef = useRef<Slider>(null);

  // Add state variable to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // ---------- HANDLERS ----------
  const handlePrevClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickPrev();
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const handleNextClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickNext();
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  // ------- ONCE CHANGE ----------
  useEffect(() => {
    dispatch(RequestGetCategories());
  }, []);

  var settings = {
    className: "serv",
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    // initialSlide: 0,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box mt="48px" sx={{position:"relative" }}>
      {cateLoading ? (
        <Slider {...settings} ref={arrowRef}>
          {Array.from(new Array(3)).map((item, index) => {
            return (
              <Box key={index} >
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={'100%'}
                  sx={{
                    textAlign: "center",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </Box>
            );
          })}
        </Slider>
      ) : (
        <Slider {...settings} ref={arrowRef}>
          {categories?.map((cate: CategoriesState) => {
            return (
              <Box
                key={cate.id}
                onClick={() => {
                  navigate({
                    pathname: '/shop',
                    search: '?category_ids=' + cate?.id,
                  });
                  dispatch(setBread(cate.name));
                }}
                sx={{ cursor: "pointer", borderRadius: "8px"}}
                
              >
                <ServiceItem cate={cate} />
              </Box>
            );
          })}
        </Slider>
      )}

      <div className="slider-arrow">
        {currentSlideIndex > 0 && (
          <button onClick={handlePrevClick} className="back">
            <NavigateBeforeIcon />
          </button>
        )}
        <button onClick={() => handleNextClick()} className="next">
          <NavigateNextIcon />
        </button>
      </div>
    </Box>
  );
};

export default ServicesSection;
