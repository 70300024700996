import { Box, Typography, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { SVGComplain, SVGMessage } from "../../assets/svg/complain";
import rafalLogo from "../../assets/logo192.png";
import { MainButton } from "../../style/style";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { t } from "i18next";

const ComplainServices = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");

  const token = useSelector((state: RootState) => state.user.user);
  const { profile } = useSelector((state: RootState) => state.profile);

  // const userDetails = localStorage.getItem("userDetails");
  // const userInfo = userDetails ? JSON.parse(userDetails) : null;

  const RequestPostComplain = async () => {
    if (message === "") {
      toast.error("add complain please");
      return false;
    }

    const req = axios
      .post(
        `${BASEURL}/complaints/`,
        {
          name: profile?.first_name,
          phone: profile?.phone,
          coplaint_message: message,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        if (res?.request.status === 201) {
          toast.success(t("message_sent"));
          setMessage("");
          setIsOpen(false)
        }
      });

    return req;
  };

  const setOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          height: "100%",
          right: "20px",
          top: "80%",
          zIndex: "9999999",
        }}
      >
        <Box sx={{ position: "relative", height: "100%" }}>
          {/* BUTton */}
          <Box
            sx={{
              background: "#FFF",
              position: "absolute",
              top: "-400px",
              width: "328px",
              right: "14px",
              boxShadow:
                "2px 2px 10px 0px rgba(27, 27, 27, 0.02), -2px 0px 10px 0px rgba(27, 27, 27, 0.02)",
              borderRadius: "0px 0px 4px 4px",
              pb: "10px",
            }}
            className={isOpen ? "complain" : "complain-hidden"}
          >
            <Box sx={{ background: "#F0F0F0", padding: "16px" }}>
              <Stack direction="row" alignItems={"center"} gap="10px">
                <img
                  src={rafalLogo}
                  alt="rafal logo"
                  style={{ width: "40px", height: "40px" }}
                />
                <Typography>{t('rafal_heading')}</Typography>
              </Stack>
              <Typography mt="10px">
                {t('hi_messages')}
              </Typography>
            </Box>
            <Box
              sx={{
                "& textarea": {
                  width: "100%",
                  border: "none",
                  outline: "none",
                  height: "auto",
                  resize: "none",
                },
                m: "16px",
              }}
            >
              <textarea
                name=""
                id=""
                cols={60}
                rows={10}
                placeholder={t('complain_placeholder')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <Box sx={{ textAlign: "start" }}>
                <MainButton
                  sx={{ py: "9.5px !important" }}
                  onClick={() => RequestPostComplain()}
                >
                  {t("send")}
                </MainButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              right: "0px",
            }}
            onClick={setOpen}
          >
            <Box sx={{ position: "relative" }}>
              <Box>{SVGComplain}</Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      d="M26.055 28.1309L17.9872 19.9313L9.93255 28.1309C9.41842 28.6451 8.57473 28.6451 8.07378 28.1178C7.59921 27.6168 7.59921 26.8258 8.08697 26.3381L16.1284 18.0066L8.08697 9.76743C7.59921 9.27967 7.59921 8.4887 8.07378 7.98776C8.57473 7.46045 9.41842 7.44727 9.91936 7.97458L17.9872 16.1874L26.055 7.89548C26.5691 7.36817 27.4128 7.36817 27.9138 7.89548C28.4015 8.39642 28.4015 9.20057 27.9138 9.70151L19.8196 17.9934L27.9006 26.3117C28.3883 26.8127 28.3883 27.6168 27.9006 28.1178C27.3996 28.6451 26.5691 28.6451 26.055 28.1309Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  SVGMessage
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ComplainServices;
