import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { MainButton } from "../../style/style";
import i18next, { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { Box } from "@mui/material";
import EmptyItems from "../common/emptyItems";
import {
  RequestGetCompare,
  RequestPostCompare,
} from "../../store/comparison/comparisonSlice";
import { DeleteIcon } from "../../assets/svg/svg";
import useAddToCart from "../../hooks/useAddToCart";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import useCustomHooks from "../../hooks/customHooks";
import compareEmpty from "../../assets/common/compareEmpty.png";
import styled from "@emotion/styled";


export const TableItem = styled(TableCell)({
  textAlign: i18next.language === "ar" ? "right" : "left",
  fontSize: "32px",
  fontWeight: "500",
  maxWidth: "calc(100% / 3)",
  "&.MuiTableCell-root": {
    borderRight: "1px solid rgba(185, 185, 185, 1) !important",
  },
});

export const TableLead = styled(Typography)({
  display: "flex",
  alignItems: i18next.language === "ar" ? "right" : "left",
  justifyContent: i18next.language === "ar" ? "start" : "start",
  height: "100%",
  fontSize: "18px",
  fontWeight: 600,
});

export default function TableComparsion() {

  // -------- SELECTOR --------
  const { compare, loading } = useSelector((state: RootState) => state.compare);
  const user = useSelector((state: RootState) => state.user.user);
  const lang = useSelector((state: RootState) => state.global.mainLang);

  // -------- DISPATCH --------
  const dispatch = useDispatch<AppDispatch>();

  // -------- HOOKS --------
  const handleAddItem = useAddToCart();
  const handleConsole = useCustomHooks();

  // -------- FUNCTIONS --------
  const handleAddToWishList = (product: any) => {
    if (user) {
      handleConsole(product?.id, product?.is_wishlist);
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType("login"));
    }
  };

  // TABLE TD
  const TableTd = styled(TableCell)({
    width: "33%",
    "&.MuiTableCell-root": {
      borderRight: "1px solid rgba(185, 185, 185, 1) !important",
      textAlign: lang === "ar" ? "start" : "left",
    },
  });
  if (loading) {
    return <h1>Loading</h1>;
  }
  return (
    <>
      {compare?.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
          <Table sx={{ border: "1px solid #B9B9B9" }} aria-label="simple table">
            {/* <TableHead> */}

            {/* </TableHead> */}
            <TableBody>
              <TableRow sx={{ background: "#FBFBFB" }}>
                <TableCell
                  sx={{
                    "&.MuiTableCell-root": {
                      borderRight:
                        "1px solid rgba(185, 185, 185, 1) !important",
                    },
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "500",
                    maxWidth: "calc(100% / 3)",
                  }}
                  // rowSpan={3}
                >
                  {compare.length} {t("product")}
                </TableCell>
                {compare.map((product: any) => (
                  <>
                    <TableCell
                      key={product.id}
                      sx={{
                        "&.MuiTableCell-root": {
                          borderRight:
                            "1px solid rgba(185, 185, 185, 1) !important",
                        },
                        textAlign: "center",
                        maxWidth: "calc(100% / 3)",
                      }}
                    >
                      <Box
                        onClick={() => {
                          dispatch(RequestPostCompare(product?.id)).then(() => {
                            dispatch(RequestGetCompare());
                          });
                        }}
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        {DeleteIcon}
                      </Box>
                      <Box
                        sx={{
                          height: "320px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={product.image}
                          alt={product.name}
                          style={{ height: "208px", width: "211.59px" }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {product.name}
                      </Typography>
                      <MainButton
                        sx={{
                          padding: {
                            xs: "8px 20px !important",
                            md: "8px 60px !important",
                          },
                          mt: "23px",
                        }}
                        onClick={() => handleAddItem(1, product?.id,product?.color?.hex_value,product?.color?.images[0]?.image,product?.name)}
                      >
                        <span
                          style={{ display: "inline-block", margin: "0 8px" }}
                        >
                          +
                        </span>
                        {t("AddToCart")}
                      </MainButton>
                      <Typography
                        sx={{
                          color: "rgba(245, 132, 31, 1)",
                          mt: "15px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddToWishList(product);
                        }}
                      >
                        {t("save for later")}
                      </Typography>
                    </TableCell>
                  </>
                ))}
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem
                // rowSpan={3}
                >
                  <TableLead>{t("price")}</TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd
                    key={row.name}
                    component="th"
                    scope="row"
                    sx={{
                      color: "#1F90F5",
                    }}
                  >
                    {row.price === null ? "-" : `${row.price} ${t("egp")}`}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem>
                  <TableLead>{t("brand")}</TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.brand == null ? "-" : row.brand}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem>
                  <TableLead>{t("packagedimensions")}</TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.package_dimensions == null
                      ? "-"
                      : row.package_dimensions}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem
                // rowSpan={3}
                >
                  <TableLead
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {t("kilogramscapacity")}
                  </TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.kilograms_capacity == null
                      ? "-"
                      : row.kilograms_capacity}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem>
                  <TableLead>{t("numberofexcitations")}</TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.number_of_excitations == null
                      ? "-"
                      : row.number_of_excitations}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem>
                  <TableLead>{t("specialfeatures")}</TableLead>
                </TableItem>
                {compare.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.special_features == "" ? "-" : row.special_features}
                  </TableTd>
                ))}
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableItem>
                  <TableLead>{t("itemweight")}</TableLead>
                </TableItem>
                {compare?.map((row: any) => (
                  <TableTd key={row.name} component="th" scope="row">
                    {row.item_weight == null ? "-" : row.item_weight}
                  </TableTd>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyItems image={compareEmpty} />
      )}
    </>
  );
}
