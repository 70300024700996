import { Box, Container, Typography } from "@mui/material";
import Footer from "../components/layout/footer";
import TableCart from "../components/cart/tableCart";
import HeroHeading from "../components/common/HeroHeading";
import { MainButton } from "../style/style";
import BreadCrumbs from "../components/common/breadCrumbs";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Link } from "react-router-dom";
import EmptyItems from "../components/common/emptyItems";
import cartEmpty from "../assets/common/savedEmpty.png";
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";
const Cart = () => {
  // tokens
  const token = useSelector((state: RootState) => state.user.user);

  //------ items
  const items = useSelector((state: RootState) => state.cart.items);
  const AllItems = useSelector((state: RootState) => state.cart.all_items);
  const guestAllItems = useSelector(
    (state: RootState) => state.guestCart.all_items
  );
  const guestItems = useSelector((state: RootState) => state.guestCart.items);

  //------ loading
  const loading = useSelector((state: RootState) => state.cart.loading);
  const guestLoading = useSelector(
    (state: RootState) => state.guestCart.loading
  );

  //------ CONSTANT
  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    { label: t("Cart"), link: "/cart", active: true },
  ];

  // Initialize Facebook Pixel
// ReactPixel.init('346743781561123'); // Replace with your actual Pixel ID
// const fbq = ReactPixel.fbq;

// const checkoutmetaPexil=(items: any, guestItems: any)=>{
//   if (typeof fbq === 'function') {
//     fbq('track', 'InitiateCheckout', {
//       content_name: items?.name||guestItems?.name,
//       content_ids: [items?.product_id]||[guestItems?.product_id],
//       value: items.quantity||guestItems?.quantity, // Adjust the value as necessary
//       currency: 'EGP',
//       cart_ulr: window.location.href,
//     });
//   }
// }
  return (
    <Box>
       <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'ViewContent');,
        fbq('track', 'AddToCart');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
      <Container>

        {/* ---------- BREADCRUMBS ----------- */}
        <Box mt="48px" mb="40px">
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        {/* ---------- HeroHeading ----------- */}
        <HeroHeading heading={t("your cart")} />
        
        {/* ------- TABLE OF CART USER AND GUEST --------- */}
        {token ? ( // length of cart of user
          items?.length > 0 ? (
            <TableCart items={items} />
          ) : (
            <EmptyItems image={cartEmpty} loading={loading} />
          ) // length of guest cart
        ) : guestItems?.length > 0 ? (
          <TableCart items={guestItems} />
        ) : (
          <EmptyItems image={cartEmpty} loading={guestLoading} />
        )}


        {/* ------- TOTAL PRICE AND CHECKOUT BUTTON --------- */}
        <Box mt="40px">
          {token ? (
            items.length > 0 ? (
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                {t("total")} : {AllItems?.total_amount} {t("egp")}
              </Typography>
            ) : (
              ""
            )
          ) : guestItems.length > 0 ? (
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              {t("total")} : {guestAllItems?.total_amount} {t("egp")}
            </Typography>
          ) : (
            ""
          )}

          {(token && items.length > 0) || guestItems.length > 0 ? (
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <MainButton
                sx={{ padding: { md: "15px 141px !important" }, mt: "32px" }}
                // onClick={()=>checkoutmetaPexil(items,guestItems)}
              >
                {t("checkout")}
              </MainButton>
            </Link>
          ) : (
            ""
          )}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Cart;
