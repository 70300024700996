import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import SideCartEmpty from "./sideCartEmpty";
import SideCartProducts from "./sideCartProducts";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SideCartDialog({ open, onClose }: any) {
  // ------- STATE --------
  const [state, setState] = React.useState({
    right: false,
    left: false
  });

  // ------- SELECTOR --------
  const lang = useSelector((state: RootState) => state.global.mainLang)

  // tokens
  const token = useSelector((state: RootState) => state.user.user);
  const guestToken = useSelector((state: RootState) => state.guestCart.cartID);

  // items
  const { items, all_items } = useSelector((state: RootState) => state.cart);
  const guestItems = useSelector((state: RootState) => state.guestCart.items);
  const guestAllItems = useSelector((state: RootState) => state.guestCart.all_items);


  // ------- responsive -------
  const matches = useMediaQuery("(max-width:600px)");


  // ------- GLBOAL FUNC -------
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

// commit
  // LIST ITEM 
  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: matches ? "100%" : "500px",
        padding: "32px",
        height: "100%",
        direction: lang === "en" ? "ltr" : "rtl"
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ paddingTop: "130px", height: "100%" }}>
        {token && items.length > 0 ? (
          <SideCartProducts items={items} total_products_price={all_items?.total_amount} />
        ) : guestToken && guestItems.length > 0 ? (
          <SideCartProducts items={guestItems} total_products_price={guestAllItems?.total_amount} />
        ) : (
          <SideCartEmpty />
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            dir={lang == "ar" ? "rtl" : "ltr"}
            anchor={anchor}
            open={open}
            onClose={onClose}
            disableScrollLock={true}
            sx={{
              "&.MuiModal-root": {
                zIndex: "11",
                top: "80px",
              },
              "& .MuiModal-backdrop": {
                background: "rgba(27, 27, 27, 0.10) !important",
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
