import { Typography, Box } from "@mui/material";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../assets/payment/animate-1.json";
import { MainButton } from "../style/style";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Helmet } from "react-helmet";

const PaymentSuccess = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  };

  const { View } = useLottie(options);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        flex: "1",
        textAlign: "center",
      }}
    >
      <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'ViewContent');
        fbq('track', 'Purchase', {value: 0.00, currency: 'EGP'});`,

      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ width: "300px", margin: "auto" }}>{View}</Box>
        <Typography>{t("success1")}</Typography>
        <Typography>
          {t("success2")}
        </Typography>
      </Box>
      <Box sx={{ mt: "40px" }}>
        <MainButton onClick={() => navigate("/profile/order")}>
          {t("fail3")}
        </MainButton>{" "}
        <br />
        <button
          onClick={() => navigate("/")}
          className="main-text"
          style={{ marginTop: "15px", outline: "none", border: "none", cursor:"pointer" }}
        >
          {t("fail4")}
        </button>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
