import React, { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import HeroHeading from "../components/common/HeroHeading";
import Footer from "../components/layout/footer";
import InputField from "../components/common/inputs/InputField";
import ProductCart from "../components/cart/productCart";
import BreadCrumbs from "../components/common/breadCrumbs";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import CheckoutPrice from "../components/cart/checkoutPrice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MainButton } from "../style/style";
import useCheckout from "../hooks/useCheckout";
import RadioGroup from "../components/common/radioGroup";
import BasicModal from "../components/common/basicModel";
import { setOpenLink } from "../store/globalSlice";

import CircularProgress from "@mui/material/CircularProgress";

import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";
import { RequestGetGuestCart } from "../store/guestCart/guestCart";
import { RequestGetCart } from "../store/cart/cartSlice";
import { Helmet } from "react-helmet";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",
  border: "none",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Checkout = () => {
  // ------- STATE -------
  const [open, setOpen] = React.useState(false);
  //--------- DISPATCH ------------
  const dispatch = useDispatch<AppDispatch>();

  //-------- CONSTANTS ------------
  const crumbs = [
    { label: `${t("Home")}`, link: "/", active: false },
    { label: `${t("checkout")}`, link: "/checkout", active: true },
  ];

  //-------- SELECTOR --------------
  const lang = useSelector((state: RootState) => state.global.mainLang);
  const token = useSelector((state: RootState) => state.user.user);
  const { items, cartUser } = useSelector((state: RootState) => state.cart);
  const guestItems = useSelector((state: RootState) => state.guestCart.items);
  const guestCartUser = useSelector(
    (state: RootState) => state.guestCart.cartUser
  );
  const { paymentLoading, openLink } = useSelector(
    (state: RootState) => state.global
  );
  const { loading } = useSelector((state: RootState) => state.checkout);

  // --------- GLOBAL FUNC ---------
  const handleCheckout = useCheckout();

  const handleClose = () => setOpen(false);

  // ---------- FORMIK ----------
  const formik = useFormik({
    initialValues: {
      first_name: "",
      second_name: "",
      email: "",
      phone: "",
      country: "EG",
      city: "",
      region: "",
      address: "",
      apartment: "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(`${t("Please enter first name")}`).min(2, ` اقل عدد حروف هو 2`) .matches(/^\S*$/, `${t(" يجب ان لا يحتوى على مسافات")}`),
      second_name: Yup.string().required(`${t("Please enter second name")}`).min(2, ` اقل عدد حروف هو 2`) .matches(/^\S*$/, `${t(" يجب ان لا يحتوى على مسافات")}`),
      address: Yup.string().trim().required(`${t("Please enter address")}`).test('no-only-spaces', `${t("Please enter address")}`, value => !!(value && value.trim().length > 0)),
      city: Yup.string().trim().required(`${t("enter_city")}`).test('no-only-spaces', `${t("enter_city")}`, value => !!(value && value.trim().length > 0)),
      region: Yup.string().required(`${t("enter_region")}`).test('no-only-spaces', `${t("enter_region")}`, value => !!(value && value.trim().length > 0)),
      email: Yup.string()
        .email(t("Valid_email"))
        .required(`${t("Valid_email")}`)
        .matches(
          /^[^\s@]+@(gmail\.com|yahoo\.com|yourdomain\.com)$/i,
          `${t("Valid_email")}`
        ),
      phone: Yup.string()
        .required(`${t("please enter phone")}`)
        .matches(/^0\d+$/, `${t("Valid_number")}`)
        .max(11, `${t("Valid_number")}`)
        .min(11, `${t("Valid_number")}`),
    }),
    onSubmit: (values, { setSubmitting, resetForm, setErrors }) => {
      handleCheckout(Cookies.get("mainCartId"), values);
      // window.location.reload();
    },
  });
  useEffect(() => {
      dispatch(RequestGetGuestCart());
     {token&& dispatch(RequestGetCart())} 
     }, []);
  return (
    <Box>
      <Container sx={{ mt: "40px" }}>
      <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'ViewContent');
        fbq('track', 'InitiateCheckout');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
        {/* ---------- breadCrumb  ---------- */}
        <Box mt="48px" mb="40px">
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        <Grid container spacing={4}>
          {/* ---------- GRID 1 ---------- */}
          {/* ------ CHECKOUT FORM  -------*/}
          <Grid item xs={12} md={7}>
            {/* ---------- TODO: make it as a form component  ---------- */}
            <Box sx={{ height: "100%", position: "relative" }}>
              <Box sx={{ position: "sticky", top: "130px" }}>
                <HeroHeading heading={t("Checkout address")} />
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "14px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "14px",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      <Box sx={{ flex: "1" }}>
                        <InputField
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder={t("firstname")}
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          errors={
                            formik.touched.first_name &&
                            formik.errors.first_name
                              ? formik.errors.first_name
                              : ""
                          }
                        />
                      </Box>
                      <Box sx={{ flex: "1" }}>
                        <InputField
                          id="second_name"
                          name="second_name"
                          type="text"
                          placeholder={t("lastname")}
                          value={formik.values.second_name}
                          onChange={formik.handleChange}
                          errors={
                            formik.touched.second_name &&
                            formik.errors.second_name
                              ? formik.errors.second_name
                              : ""
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <InputField
                        id="phone"
                        name="phone"
                        type="number"
                        placeholder={t("phonenumber")}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.phone && formik.errors.phone
                            ? formik.errors.phone
                            : ""
                        }
                      />
                    </Box>
                    <Box>
                      <InputField
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t("emailaddress")}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : ""
                        }
                      />
                    </Box>

                    <Box>
                      <InputField
                        id="address"
                        name="address"
                        type="address"
                        placeholder={t("address")}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : ""
                        }
                      />
                    </Box>

                    <Box>
                      <InputField
                        id="city"
                        name="city"
                        type="text"
                        placeholder={t("city")}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.city && formik.errors.city
                            ? formik.errors.city
                            : ""
                        }
                      />
                    </Box>
                    <Box>
                      <InputField
                        id="region"
                        name="region"
                        type="text"
                        placeholder={t("region")}
                        value={formik.values.region}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.region && formik.errors.region
                            ? formik.errors.region
                            : ""
                        }
                      />
                    </Box>

                    <Box>
                      <InputField
                        id="apartment"
                        name="apartment"
                        type="address"
                        placeholder={t("addressliving")}
                        value={formik.values.apartment}
                        onChange={formik.handleChange}
                        errors={
                          formik.touched.apartment && formik.errors.apartment
                            ? formik.errors.apartment
                            : ""
                        }
                      />
                    </Box>

                    <Box mt="20px">
                      <HeroHeading heading={t("paymentmethod")} />
                      <RadioGroup />
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <MainButton type="submit" sx={{ minWidth: "300px" }}>
                        {loading ? t("loading") : t("continuetopayment")}
                      </MainButton>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Grid>
          {/* ---------- GRID 2 ---------- */}
          {/* ---------- CART  ---------- */}
          <Grid item xs={12} md={5} className="line">
            <Box
              sx={{
                padding: { md: "0 40px", lg: "0 88px" },
                "&.line::after": {
                  right: lang == "ar" ? "0" : "auto",
                  left: lang == "ar" ? "auto" : "0",
                },
              }}
              // className="line"
            >
              {token
                ? items?.map((item: any) => {
                    return <ProductCart key={item.id} item={item} />;
                  })
                : guestItems.map((item: any) => {
                    return <ProductCart key={item.id} item={item} />;
                  })}

              {token ? (
                <CheckoutPrice cartUser={cartUser} />
              ) : (
                <CheckoutPrice cartUser={guestCartUser} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* ---------- FOOTER  ---------- */}
      <Footer />

      {/* ---------- PAYMENT  ---------- */}
      {paymentLoading ? (
        <div>
          <Modal
            open={paymentLoading}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .MuiBox-root": {
                boxShadow: "0",
                outline: "none",
              },
            }}
          >
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </Box>
          </Modal>
        </div>
      ) : (
        <BasicModal
          open={openLink}
          onClose={() => dispatch(setOpenLink(false))}
        />
      )}
    </Box>
  );
};

export default Checkout;
