import { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Footer from "../components/layout/footer";
import { colors } from "../utils/theme";
import RecommendSection from "../components/home/LikeYouSection";
import ReviewSection from "../components/productDetails/reviewSection";
import BreadCrumbs from "../components/common/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestGetProduct } from "../store/product/productSlice";
import ProductsInfo from "../components/productDetails/productsInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { t } from "i18next";
import "../assets/Vector (5).png";
import { Helmet } from "react-helmet";

export const HeadingProduct = styled(Typography)({
  fontSize: "24px",
  fontWeight: "600",
  lineHeight: "29.26px",
  maxWidth: "607px",

  "@media (max-width: 600px)": {
    padding: "50px",
  },
});

export const ListItem = styled("li")({
  fontSize: "18px",
  fontWeight: "400",
  color: "#505050",
  lineHeight: "5px",
  display: "flex",
  marginBottom: "20px",
});

export const NavbarNav = styled("ul")({
  listStyle: "disc",
  padding: "0 20px",
  margin: "8px",
  color: "#505050",
});

export const ImageContainer = styled(Box)({
  background: "#FBFBFB",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  padding: "2px",
});


const ProductDetails = () => {
  // ---------- colors ----------
  const { card } = colors;

  // ---------- SELECTORS ----------
  const { product, loading } = useSelector(
    (state: RootState) => state.product
  );

  //---------- Hooks ----------
  const refImg = useRef<HTMLImageElement | null | any>(null);
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const [mainImage, setMainImage] = useState(null);
  const [subImage, setSubImage] = useState(product?.color||product?.additional_images);
  
  // ----------- CONSTANT -----------
  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: t('shop'),
      link: "/shop",
      active: false,
      onClick: () => changeCateID(),
    },
    { label: product?.name, link: `/shop/${param?.id}`, active: true },
  ];

  // ----------- GLobaL FUNC -----------
  const changeCateID = () => {
    navigate("/shop");
    // dispatch(setBread(product?.category?.name));
  };

  // ONCE CHANGE ------
  useEffect(() => {
    dispatch(RequestGetProduct(searchParams.get("id")));
  }, [param.id]);
  
  const handleColorChange = (images: any) => {
    setMainImage(images.length>0&&images[0].image);
    setSubImage(images);
  };

  return (
    <>
      <Container>
      
        {/* ------- BreadCrumbs------- */}
        <Box mt="48px">
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        {/* ------- Prdocut DETAILS ------- */}
        <Box sx={{ padding: { xs: "0 5px", sm: "0 40px" } }}>
          <Grid container mt="59px" spacing={5}>
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ height: "100%", position: "relative" }}>
                <Box sx={{ position: "sticky", top: "137px" }}>
                  {/* <TestImage /> */}
                  {loading ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={250}
                        sx={{
                          textAlign: "center",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="80%" />
                    </>
                  ) : (
                    <Box
                      sx={{
                        background: card[100],
                        textAlign: "center",
                        mb: "8px",
                        // height: "471px",
                        overflow: "hidden",
                        height: "470px",
                        "& div": {
                          height: "470px",
                        },
                        "& img": {
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <img
                        src={mainImage|| product?.image}
                        ref={refImg}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        alt="product"
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "20px",
                      justifyContent: "flex-start",
                    }}
                  >
                    {/* <ImageContainer
                      sx={{
                        height: "94px",
                        img: {
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        },
                      }}
                      onClick={(e) => {
                        if (refImg.current) {
                          refImg.current.src = mainImage||product?.image;
                          
                        }
                      }}
                    >
                      <img src={mainImage||product?.image} alt="test" />
                    </ImageContainer> */}
                    {subImage?.map((image: any) => {
                      return (
                        <>
                          <ImageContainer
                            key={image?.id}
                            sx={{
                              height: "94px",
                              img: {
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              },
                            }}
                            id={image?.id}
                            onClick={(e) => {
                              if (refImg.current) {
                                image?.product_image && (refImg.current.src = image?.product_image);
                                image?.image && (refImg.current.src = image?.image);
                               
                              }
                            }}
                          >
                            <img src={image?.product_image||image.image} alt="test" />
                          </ImageContainer>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={12} md={7}>
              <ProductsInfo product={product} onColorChange={handleColorChange}/>
            </Grid>
          </Grid>
        </Box>

        {/* --------- REVIEW ------- */}
        <Box sx={{ mx: { md: "40px" } }}>
          <ReviewSection reviews={product?.product_reviews} product={product} />
        </Box>

        {/* ------- Recommend Section ------- */}
        <Box sx={{ mx: { md: "40px" } }}>
          <RecommendSection heading={t("Products you may Like")} categoryIds={product.category}/>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ProductDetails;