import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import logo from "../../assets/logo.png";
import SearchInput from "../common/inputs/searchInput";
import { colors } from "../../utils/theme";
import { Container } from "@mui/material";
import logo from "../../assets/logo.png";
import TopNav from "./topNav";
import { MainButton } from "../../style/style";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SideSign from "../common/sideSign";
import CloseIcon from "@mui/icons-material/Close";
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
import { cartIcon, rafalIcon, savedIcon, transferIcon, userIcon, worldIcon, } from "../../assets/svg/svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setMainLang, setOpenDrawerCart, setOpenDrawerSearch, setOpenLogin, setSignType, } from "../../store/globalSlice";
import { Link, useNavigate } from "react-router-dom";
import DialogSearch from "./dialogSearch";
import i18n from "../../i18n";
import i18next, { t } from "i18next";
import Listtoresponce from "../common/listtoresponce";
import { RequestGetGuestCart } from "../../store/guestCart/guestCart";
import { RequestGetCart } from "../../store/cart/cartSlice";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    { to: "/", text: "home" },
    { to: "/saved", text: "Wishlist" },
    { to: "/comparsion", text: "compare" },
];

export default function LastNav(props: Props) {
    const token = useSelector((state: RootState) => state.user.user);
    const errorMsg = useSelector((state: RootState) => state.user.errorMsg);
    const userDetails = useSelector((state: RootState) => state.user.userDetails);
    const lang = useSelector((state: RootState) => state.global.mainLang);
    const AllItems = useSelector((state: RootState) => state.cart.all_items);
    const guestAllItems = useSelector((state: RootState) => state.guestCart.all_items);
    const navigate = useNavigate();
    const openDrawerSearch: any = useSelector(
        (state: RootState) => state.global.openDrawerSearch
    );

    const openDrawerLogin = useSelector(
        (state: RootState) => state.global.openDrawerLogin
    );
    // React.useEffect(() => {
    //     { token ? dispatch(RequestGetCart()) : dispatch(RequestGetGuestCart()) }
    // }, [])
    // const user = JSON.parse(userDetails)
    // const user = JSON.parse(userDetails)
    const { window } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [openDrawerLogin, setopenDrawerLogin] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch<AppDispatch>();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [anchorNotification, setAnchorNotification] =
        React.useState<null | HTMLElement>(null);
    const openNotification = Boolean(anchorNotification);
    const handleClickNotification = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorNotification(event.currentTarget);
    };
    const handleCloseNotification = () => {
        setAnchorNotification(null);
    };
    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
    };

    function sendaccount(link: string) {
        dispatch(setOpenLogin(true));
        dispatch(setSignType(link));
        dispatch(setOpenDrawerCart(false));
        handleDrawerToggle();
    }

    function sendlang(link: string) {
        dispatch(setMainLang(link));
        changeLanguage(link);
        dispatch(setMainLang(link));
        localStorage.setItem("lang", link);
    }

    const listofmobile = [
        {
            icon: userIcon,
            name: `${t("Account")}`,
            list: [
                {
                    name: `${t("login")}`,
                    link: "login",
                    onclick: () => sendaccount("login"),
                },
                {
                    name: `${t("register")}`,
                    link: "sign",
                    onclick: () => sendaccount("sign"),
                },
            ],
        },
        {
            icon: worldIcon,
            name: "Language",
            list: [
                { name: "English", link: "English", onclick: () => sendlang("en") },
                { name: "Arabic", link: "Arabic", onclick: () => sendlang("ar") },
            ],
        },
    ];
    const drawer = (
        <Box
            sx={{
                textAlign: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: { xs: "flex", sm: "flex", md: "none" },
                        justifyContent: {
                            xs: "space-between",
                            md: "space-between",
                            sm: "space-between",
                            lg: "space-between",
                        },
                        alignItems: "center",
                        width: "100%",
                        my: "15px",
                        // paddingTop:"8px !important",
                    }}
                >
                    <Box sx={{ padding: "10px 15px" }}>
                        <Link to="/">
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: "center",
                                }}
                            >
                                <img src={logo} alt="logo" />
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <IconButton
                            // color="#fff"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setMobileOpen(!mobileOpen)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <List
                        sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            direction: i18next.language === "en" ? "ltr" : "rtl",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {!token ? (
                            <Box onClick={() => sendaccount("login")}>
                                <Listtoresponce
                                    icon={savedIcon}
                                    primary={"Saved list"}
                                    type={false}
                                />
                            </Box>
                        ) : (
                            <Box onClick={() => handleDrawerToggle()}>
                                <Link
                                    to="/saved"
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    <Listtoresponce
                                        icon={savedIcon}
                                        primary={"Saved list"}
                                        type={false}
                                    />
                                </Link>
                            </Box>
                        )}
                        {!token ? (
                            <Box onClick={() => sendaccount("login")}>
                                <Listtoresponce
                                    icon={transferIcon}
                                    primary={"Compare List"}
                                    type={false}
                                />
                            </Box>
                        ) : (
                            <Box onClick={() => handleDrawerToggle()}>
                                <Link
                                    to="/comparsion"
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    <Listtoresponce
                                        icon={transferIcon}
                                        primary={"Compare List"}
                                        type={false}
                                    />
                                </Link>
                            </Box>
                        )}

                        {listofmobile.map((ele, index) => (
                            <>
                                {index === 0 ? (
                                    token ? (
                                        <Box
                                            key={index}
                                            onClick={() => handleDrawerToggle()}>
                                            <Link
                                                to="/profile"
                                                style={{ textDecoration: "none", color: "inherit" }}
                                            >
                                                <Listtoresponce
                                                    icon={userIcon}
                                                    primary={"Account"}
                                                    type={false}
                                                />
                                            </Link>
                                        </Box>
                                    ) : (
                                        <Listtoresponce
                                            key={index}
                                            icon={ele.icon}
                                            primary={ele.name}
                                            listofprimary={listofmobile.length > 0 ? ele.list : []}
                                        />
                                    )
                                ) : (
                                    <Listtoresponce
                                        key={index}
                                        icon={ele.icon}
                                        primary={ele.name}
                                        listofprimary={listofmobile.length > 0 ? ele.list : []}
                                    />
                                )}
                            </>
                        ))}

                        {/*  ------------------------------------- */}

                        {
                            !token && (
                                <Box
                                    onClick={() => handleDrawerToggle()}>
                                    <Link
                                        to="/profile/order"
                                        style={{ textDecoration: "none", color: "inherit" }}
                                    >
                                        <Listtoresponce
                                            icon={rafalIcon}
                                            primary={"urOrders"}
                                            type={false}
                                        />
                                    </Link>
                                </Box>
                            )
                        }
                    </List>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            height: { sm: "68vh", xs: "60vh" },
                            flexDirection: "column",
                        }}
                    >
                        <Typography component={"span"}>
                            {t("For inquiries")}
                            <Typography component={"span"}>(+20) 1010628457</Typography>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link
                to={item.to}
                style={{
                  textAlign: "center",
                  textDecoration: "none",
                  display: "block",
                  margin: "auto",
                  color: "rgba(27, 27, 27, 1)",
                }}
              >
                <ListItemText primary={item.text} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!openDrawerLogin) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
        // setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    /* open language menu */
    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
        null
    );
    const openLang = Boolean(anchorElLang);
    const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElLang(event.currentTarget);
    };
    const handleCloseLang = () => {
        setAnchorElLang(null);
    };

    /* open notification menu */
    // const [anchorNotification, setAnchorNotification] =
    //   React.useState<null | HTMLElement>(null);
    // const openNotification = Boolean(anchorNotification);
    // const handleClickNotification = (
    //   event: React.MouseEvent<HTMLButtonElement>
    // ) => {
    //   setAnchorNotification(event.currentTarget);
    // };

    // get Height
    const [height, setHeight] = React.useState(0);
    const navRef = React.useRef<any>(null);

    React.useEffect(() => {
        setHeight(navRef.current.offsetHeight);
    }, []);

    // search nav
    const [searchDialog, setSearchDialog] = React.useState(false);
    return (
        <Box
            sx={{
                position: "sticky",
                top: "0",
                zIndex: "12",
                width: "100%",
                direction: "ltr !important",
            }}
            ref={navRef}
        >
            <TopNav />
            <CssBaseline />

            <AppBar
                component="nav"
                sx={{
                    "&.MuiAppBar-colorPrimary": {
                        background: "#fff !important",
                        color: "#000 !important",
                        boxShadow: "none",
                    },
                    "&.MuiButton-root": {
                        color: "#000",
                    },
                    "&.MuiPaper-root": {
                        position: "relative",
                        paddingRight: "0px !important",
                    },
                }}
            >
                <Container>
                    <Toolbar sx={{
                        width: "100%",
                        paddingTop: "8px !important",
                        paddingRight: "0px",
                        paddingLeft: "0px !important"
                    }}>
                        {/* mobile Icon  for responsive */}
                        <Box
                            sx={{
                                display: { xs: "flex", sm: "flex", md: "none" },
                                justifyContent: {
                                    xs: "space-between",
                                    md: "space-around",
                                    sm: "space-between",
                                    lg: "space-around",
                                },
                                alignItems: "center",
                                width: "100%",
                                my: "15px",
                            }}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: "none" } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Box>
                                <Link to="/">
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            flexGrow: 1,
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={logo} alt="logo" />
                                    </Typography>
                                </Link>
                            </Box>

                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>

                                <Box
                                    sx={{
                                        "& svg": {
                                            fontSize: {
                                                xs: "26px !important",
                                                md: "26px !important",
                                            },
                                        },
                                    }}
                                    onClick={() => dispatch(setOpenDrawerSearch(true))}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.3853 15.446C13.0375 16.5229 11.3284 17.0429 9.60922 16.8991C7.88999 16.7552 6.29108 15.9586 5.14088 14.6727C3.99068 13.3869 3.3765 11.7094 3.42449 9.9848C3.47248 8.26024 4.17898 6.6195 5.39891 5.39958C6.61883 4.17965 8.25956 3.47315 9.98413 3.42516C11.7087 3.37717 13.3862 3.99135 14.6721 5.14155C15.9579 6.29175 16.7546 7.89066 16.8984 9.60989C17.0422 11.3291 16.5222 13.0382 15.4453 14.386L20.6013 19.541C20.675 19.6097 20.7341 19.6925 20.7751 19.7845C20.8161 19.8765 20.8381 19.9758 20.8399 20.0765C20.8417 20.1772 20.8232 20.2772 20.7855 20.3706C20.7477 20.464 20.6916 20.5488 20.6204 20.62C20.5492 20.6913 20.4643 20.7474 20.3709 20.7851C20.2775 20.8228 20.1775 20.8414 20.0768 20.8396C19.9761 20.8378 19.8768 20.8158 19.7848 20.7748C19.6928 20.7338 19.61 20.6747 19.5413 20.601L14.3853 15.446ZM6.45933 13.884C5.72537 13.15 5.22549 12.2148 5.02284 11.1968C4.8202 10.1787 4.92391 9.12344 5.32084 8.1643C5.71778 7.20517 6.39014 6.38523 7.25295 5.8081C8.11575 5.23098 9.13027 4.92258 10.1683 4.92189C11.2063 4.92119 12.2213 5.22822 13.0848 5.80418C13.9484 6.38014 14.6219 7.19917 15.0201 8.15778C15.4183 9.11638 15.5235 10.1715 15.3222 11.1898C15.1209 12.2082 14.6223 13.144 13.8893 13.879L13.8843 13.884L13.8793 13.888C12.8944 14.8706 11.5598 15.4221 10.1685 15.4214C8.77725 15.4206 7.44318 14.8677 6.45933 13.884Z"
                                            fill="#1B1B1B"
                                        />
                                    </svg>
                                </Box>
                                <Box
                                    sx={{
                                        "& svg": {
                                            fontSize: {
                                                xs: "20px !important",
                                                md: "26px !important",
                                            },
                                        },
                                        position: "relative",
                                    }}
                                    onClick={() => navigate("/cart")}
                                >

                                    {cartIcon}
                                </Box>

                            </Box>
                        </Box>

                        {/* Grid one */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", sm: "none", md: "flex", gap: "8px" },
                                alignItems: "center",
                            }}
                        >
                            <Link to="/">
                                <Typography variant="h6" component="div">
                                    <img src={logo} alt="logo" />
                                </Typography>
                            </Link>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: "none", lg: "flex" },
                                flexGrow: 3,
                                justifyContent: "center",
                                alignItems: "center",
                                gap: { sm: "20px", lg: "59px" },
                            }}
                        >
                            <SearchInput />
                        </Box>
                        {/* Grid two */}
                        <Box
                            sx={{
                                display: { xs: "none", sm: "none", md: "flex" },
                                flexGrow: 2,
                                justifyContent: "end",
                                alignItems: "center",
                                gap: { sm: "20px", md: "35px", lg: "30px", xl: "59px" },
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: "block", lg: "none" },
                                    "& svg": {
                                        fontSize: {
                                            xs: "26px !important",
                                            md: "26px !important",
                                        },
                                    },
                                }}
                                onClick={() => setSearchDialog(true)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.3853 15.446C13.0375 16.5229 11.3284 17.0429 9.60922 16.8991C7.88999 16.7552 6.29108 15.9586 5.14088 14.6727C3.99068 13.3869 3.3765 11.7094 3.42449 9.9848C3.47248 8.26024 4.17898 6.6195 5.39891 5.39958C6.61883 4.17965 8.25956 3.47315 9.98413 3.42516C11.7087 3.37717 13.3862 3.99135 14.6721 5.14155C15.9579 6.29175 16.7546 7.89066 16.8984 9.60989C17.0422 11.3291 16.5222 13.0382 15.4453 14.386L20.6013 19.541C20.675 19.6097 20.7341 19.6925 20.7751 19.7845C20.8161 19.8765 20.8381 19.9758 20.8399 20.0765C20.8417 20.1772 20.8232 20.2772 20.7855 20.3706C20.7477 20.464 20.6916 20.5488 20.6204 20.62C20.5492 20.6913 20.4643 20.7474 20.3709 20.7851C20.2775 20.8228 20.1775 20.8414 20.0768 20.8396C19.9761 20.8378 19.8768 20.8158 19.7848 20.7748C19.6928 20.7338 19.61 20.6747 19.5413 20.601L14.3853 15.446ZM6.45933 13.884C5.72537 13.15 5.22549 12.2148 5.02284 11.1968C4.8202 10.1787 4.92391 9.12344 5.32084 8.1643C5.71778 7.20517 6.39014 6.38523 7.25295 5.8081C8.11575 5.23098 9.13027 4.92258 10.1683 4.92189C11.2063 4.92119 12.2213 5.22822 13.0848 5.80418C13.9484 6.38014 14.6219 7.19917 15.0201 8.15778C15.4183 9.11638 15.5235 10.1715 15.3222 11.1898C15.1209 12.2082 14.6223 13.144 13.8893 13.879L13.8843 13.884L13.8793 13.888C12.8944 14.8706 11.5598 15.4221 10.1685 15.4214C8.77725 15.4206 7.44318 14.8677 6.45933 13.884Z"
                                        fill="#1B1B1B"
                                    />
                                </svg>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    "& svg": {
                                        fontSize: { xs: "18px !important", md: "26px !important" },
                                    },
                                }}
                            >
                                {token ? (
                                    <Link to="/saved">{savedIcon}</Link>
                                ) : (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => sendaccount("login")}
                                    >
                                        {savedIcon}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", md: "block" },
                                    "& svg": {
                                        fontSize: { xs: "18px !important", md: "26px !important" },
                                    },
                                }}
                            >
                                {token ? (
                                    <Link to="/comparsion">{transferIcon}</Link>
                                ) : (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => sendaccount("login")}
                                    >
                                        {transferIcon}
                                    </Typography>
                                )}
                            </Box>

                            <Box
                                sx={{
                                    "& svg": {
                                        fontSize: { xs: "18px !important", md: "26px !important" },
                                    },
                                }}
                                className="cart_items"
                            >
                                <Link to="/cart" >{cartIcon}</Link>
                                {token && AllItems?.count > 0 &&
                                    <div className="Count_cart_items">
                                        {AllItems?.count}
                                    </div>
                                }
                                {!token && guestAllItems?.count > 0 &&
                                    <div className="Count_cart_items">
                                        {guestAllItems?.count}
                                    </div>
                                }


                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    position: "relative",
                                }}
                            >
                                <Box>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={(e) => {
                                            handleClick(e);
                                            if (token) {
                                                navigate("/profile");
                                            }
                                        }}
                                        sx={{
                                            color: "#000",
                                            "&:hover": { background: "transparent" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                "& svg": {
                                                    fontSize: {
                                                        xs: "18px !important",
                                                        md: "26px !important",
                                                    },
                                                },
                                            }}
                                        >
                                            {userIcon}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                textTransform: "capitalize",
                                                mx: "8px",
                                            }}
                                            component={"span"}
                                            onClick={() => {
                                                if (token) {
                                                    navigate("/profile");
                                                }
                                            }}
                                        >
                                            {token ? (
                                                <Link
                                                    to="/profile"
                                                    style={{ textDecoration: "none", color: "#000" }}
                                                >
                                                    {userDetails?.first_name}
                                                </Link>
                                            ) : (
                                                t("Account")
                                            )}
                                        </Typography>
                                    </Button>
                                    {!token && (
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            disableScrollLock={true}
                                            MenuListProps={{
                                                "aria-labelledby": "basic-button",
                                            }}
                                            sx={{
                                                textAlign: "center",
                                                "& .MuiPaper-root": {
                                                    padding: "5px 55px",
                                                },
                                            }}
                                        >
                                            <MainButton
                                                sx={{
                                                    padding: "5px 50px !important",
                                                    mb: "15px",
                                                    mt: "20px",
                                                    borderRadius: "0px 0px 4px 4px",
                                                }}
                                                onClick={() => {
                                                    sendaccount("login");
                                                    // dispatch(setOpenLogin(true));
                                                    handleClose();
                                                    // dispatch(setSignType("login"));
                                                }}
                                            >
                                                {t("login")}
                                            </MainButton>
                                            <Typography
                                                sx={{
                                                    mb: "20px",
                                                    color: "rgba(0, 0, 0, 0.40) !important",
                                                    fontWeight: "400",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    // dispatch(setSignType("sign"));
                                                    handleClose();
                                                    sendaccount("sign");
                                                    // dispatch(setOpenLogin(true));
                                                }}
                                            >
                                                {t("first_time_here")}{" "}
                                                <span style={{ color: colors.orange[100] }}>
                                                    {t("sign_up")}
                                                </span>
                                            </Typography>
                                        </Menu>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                onClick={() => handleDrawerToggle()}
                                sx={{
                                    borderRadius: "8px",
                                    background: "#f1c40f !important",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "5px 10px",
                                    fontWeight: "700",
                                    fontSize: "17px",
                                    cursor: "pointer",
                                    "&:hover": { background: "#f1c40f !important" },
                                }}
                            >
                                <Link
                                    to="/profile/order"
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    {t("urOrders")}
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    "& svg": {
                                        fontSize: { xs: "18px !important", md: "26px !important" },
                                    },
                                }}
                            >
                                <Button
                                    id="basic-button"
                                    aria-controls={openLang ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openLang ? "true" : undefined}
                                    onClick={handleClickLang}
                                >
                                    <Box
                                        sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                                    >
                                        {worldIcon}
                                    </Box>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElLang}
                                    open={openLang}
                                    onClose={handleCloseLang}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                    disableScrollLock={true}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguage("en");
                                            dispatch(setMainLang("en"));
                                            localStorage.setItem("lang", "en");
                                            handleCloseLang();
                                        }}
                                    >
                                        English
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguage("ar");
                                            dispatch(setMainLang("ar"));
                                            localStorage.setItem("lang", "ar");
                                            handleCloseLang();
                                        }}
                                    >
                                        Arabic
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer
                    anchor={lang === "en" ? "left" : "right"}
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    disableScrollLock={true}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "block", md: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: "80%",
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

            <SideSign
                open={openDrawerLogin}
                onClose={() => dispatch(setOpenLogin(false))}
            />
            {/* <AlertResponse type="error" message={errorMsg} horizontal="left" /> */}

            <DialogSearch
                open={openDrawerSearch}
                onClose={() => dispatch(setOpenDrawerSearch(false))}
            />
        </Box >
    );
}
