import { Box, Container } from "@mui/material";

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Footer from "../components/layout/footer";
import HeroHeading from "../components/common/HeroHeading";
import TableComparsion from "../components/comparsion/tableComparsion";
import BreadCrumbs from "../components/common/breadCrumbs";

import { AppDispatch } from "../store/store";
import { RequestGetCompare } from "../store/comparison/comparisonSlice";

import { t } from "i18next";
import { Helmet } from "react-helmet";

const Comparsion = () => {
  // ------- DISPATCH ---------
  const dispatch = useDispatch<AppDispatch>();

  // -------- CONSTANT --------
  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: t("Compare List"),
      link: "/comparsion",
      active: true,
    },
  ];

  // -------- ONCE CHANGE --------
  useEffect(() => {
    dispatch(RequestGetCompare());
  }, []);
  return (
    <Box>
      <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'PageView');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
      <Container>

        {/* --------- BREADCRUMBS --------- */}
        <Box mt="48px" mb="40px">
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        {/* --------- HeroHeading --------- */}
        <HeroHeading heading={t("Compare List")} />

        {/* --------- TABLE COMPARSION --------- */}
        <Box mt="32px" mb="48px" sx={{ overflowX: "auto" }}>
          <TableComparsion />
        </Box>

      </Container>
      <Footer />
    </Box>
  );
};

export default Comparsion;
