import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestPostToCart } from "../store/cart/cartSlice";
import {
  RequestPostToGuestCart,
} from "../store/guestCart/guestCart";
import ReactPixel from 'react-facebook-pixel';

function useAddToCart() {
  const dispatch = useDispatch<AppDispatch>();
  const query = new URLSearchParams(window.location.search);
  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);

// Initialize Facebook Pixel
ReactPixel.init('2225781867788907'); 
const fbq = ReactPixel.fbq;

  
//   quantity: any, product_id: any
  const handleAddItem = (quantity: any, product_id: any,color:any,image:any,name:string) => {
    query.set("type", "cart");
    //  Update the URL
     const newUrl = `${window.location.pathname}?${query.toString()}`;
     window.history.pushState({}, '', newUrl);
    // if (typeof fbq === 'function') {
    //   fbq('track', 'AddToCart', {
    //     content_name: name,
    //     content_ids: [product_id],
    //     value: quantity,
    //     currency: 'EGP',
    //     cart_ulr: window.location.href+"cart",
    //   });
    // }
    if (typeof fbq === 'function') {
      fbq('track','ViewContent')
      fbq('track', 'AddToCart', {
          content_name: name,
          content_ids: [product_id],
          value: quantity,
          currency: 'EGP',
          cart_url: window.location.href + "cart",
      });
  }
    // setOpenDrawer(true);
    // dispatch(setOpenDrawerCart(true))
    if (user) {
      dispatch(RequestPostToCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image}));
    } 
    else {
      dispatch(
        RequestPostToGuestCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image})
      );
    } 
    // if (guestUser !== null)
    // else {
    //   dispatch(()).then((result) => { 
    //     dispatch(
    //       RequestPostToGuestCart({ quantity: quantity, product_id: product_id })
    //     );
    //   });
    // }
  };

  return handleAddItem;
}

export default useAddToCart;
