import { useEffect } from "react";
import Container from "@mui/material/Container/Container";
import MainSlider from "../components/home/MainSlider";
import RecommendSection from "../components/home/RecommendSection";
import OfferSection from "../components/home/offerSection";

import slider1 from "../assets/home/install-1.png";
import slider2 from "../assets/home/landing-1.png";

import VideoPerview from "../components/home/videoPerview";
import Footer from "../components/layout/footer";
import ServicesSection from "../components/home/servicesSection";
import SideSign from "../components/common/sideSign";
import { t } from "i18next";
import LikeYouSection from "../components/home/LikeYouSection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import ComplainServices from "../components/home/complainServices";
import { RequestGetProfile } from "../store/profile/profileSlice";
import {
  RequestGetOffer,
  RequestGetUserOffer,
} from "../store/offer/offerSlice";
import { Helmet } from "react-helmet";

const Home = () => {
  // -------- SELECTOR --------
  const offers = useSelector((state: RootState) => state.offer.offer);
  const token = useSelector((state: RootState) => state.user.user);

  // -------- REDUX --------
  const dispatch = useDispatch<AppDispatch>();

  // -------- CONSTANT --------
  const landingInfo = [
    { image: slider2, alt: "slider 1" },
    { image: slider1, alt: "slider 1" },
    { image: slider2, alt: "slider 1" },
    { image: slider1, alt: "slider 1" },
  ];

  // -------- ONCE CHANGE --------
  useEffect(() => {
    if (token) {
      // @ts-ignore
      dispatch(RequestGetUserOffer());
      dispatch(RequestGetProfile());
    } else {
      dispatch(RequestGetOffer());
    }
  }, []);

  return (
    <>
     <Helmet>
    {/* <!-- Facebook Pixel Code --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2225781867788907');
        fbq('track', 'ViewContent');`,
      }}
    />
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=2225781867788907&ev=PageView&noscript=1" />`,
      }}
    />
    {/* <!-- End Facebook Pixel Code --> */}
  </Helmet>
      <SideSign />
      <Container>
        <MainSlider sliderInfo={landingInfo} />
        <ServicesSection />
        <LikeYouSection
          heading={t("our_products")}
          fetchUrl="/likedproducts/"
          categoryIds={[]}
        />
        {offers?.count > 0 && <OfferSection />}
        <RecommendSection
          heading={t("best_seller")}
          fetchUrl="/recommended/"
        />
        <VideoPerview />
      </Container>
      <Footer />
      {token && <ComplainServices />}
    </>
  );
};

export default Home;
