import React from 'react'
import ProfileLayout from "../../components/profile/profileLayout";
import HeroHeading from "../../components/common/HeroHeading";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

export default function TermsCondition() {
    const crumbs = [
        { label: ` ${t("Home")}`, link: "/", active: false },
        { label: `${t('terms_privacy')}`, link: `/profile/terms&condition`, active: true },
    ];
    return (
        <ProfileLayout crumbs={crumbs}>
            <HeroHeading heading={t('terms_privacy')} />

            <Box sx={{ fontSize: "18px", fontWeight: "600 !important", p: 2, wordSpacing: "2px", whiteSpace: "break-spaces", wordBreak: "break-all" }}>
                <Typography mb="16px" fontSize="22px" fontWeight={600}>
                    {t('terms_privacy1')}
                </Typography>
                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms1')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms3')}
                </Typography>
                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms4')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    <ul style={{ listStyle: "type", listStyleType: "disc" }}>
                        <li> {t('textTerms5')}</li>
                        <li> {t('textTerms6')}</li>
                        <li> {t('textTerms7')}</li>
                        <li> {t('textTerms8')}</li>
                        <li> {t('textTerms9')}</li>
                        <li> {t('textTerms10')}</li>
                        <li> {t('textTerms11')}</li>
                        <li> {t('textTerms12')}</li>
                        <li> {t('textTerms13')}</li>
                        <li> {t('textTerms14')}</li>

                    </ul>
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms15')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms16')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms17')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms18')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms19')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms20')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms21')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms22')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms23')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    <ul style={{ listStyle: "type", listStyleType: "disc" }}>
                        <li>{t('textTerms24')}</li>
                        <li>{t('textTerms25')}</li>
                        <li>{t('textTerms26')}</li>
                        <li>{t('textTerms27')}</li>

                    </ul>
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms28')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms29')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={500}>
                    {t('textTerms30')}
                </Typography>

                <Typography mb="16px" fontSize="18px" fontWeight={600}>
                    {t('textTerms31')}
                </Typography>

            </Box>
        </ProfileLayout>
    );
}
